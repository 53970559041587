import React, {useState} from 'react';
import axios from "axios"
import InnerLayout from "../components/global/innerLayout"
import {graphql, useStaticQuery} from "gatsby";
import parse from "html-react-parser";
import HeroImage from "../components/global/heroImage";
import "./free-stories-email-newsletters.scss"
import Select from "../components/global/select";
import PageTitle from "../components/global/pageTitle";
import Recaptcha from "react-recaptcha";
import useSSR from "use-ssr";

const Page = () => {

    const { isBrowser } = useSSR()

    let captchaChecked = false
    let formSubmitted = false
    let listSelected = false

    const [isCaptchaChecked, setCaptchaChecked] = useState(false)
    const [isFormSubmitted, setFormSubmitted] = useState(false)
    const [isListSelected, setListSelected] = useState(false)

    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "newsletterSignup") {
              ... on Craft_newsletterSignup_newsletterSignup_Entry {
                id
                title
                pageTitle
                newsletterHeroImage {
                  ... on Craft_newsletterHeroImage_image_BlockType {
                    image {
                      url
                      title
                      ... on Craft_websiteGeneral_Asset {
                        altText
                      }
                    }
                    imageLink {
                      linkUrl
                      openInNewTab
                    }
                  }
                }
                newsletterBody
                seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
              }
            }
          }
        }
  `)

    axios.defaults.headers.post['Content-Type'] ='application/json';

    const subscribe = (e) => {
        e.preventDefault()

        formSubmitted = true
        setFormSubmitted(true)

        let data = {
            email: e.target.elements.email.value,
            country: e.target.elements.country.value,
            lists: []
        }

        if (e.target.elements.dailyBulletin.checked) {
            data.lists.push(e.target.elements.dailyBulletin.value)
        }

        if (e.target.elements.weeklyBulletin.checked) {
            data.lists.push(e.target.elements.weeklyBulletin.value)
        }

        if (e.target.elements.faithAndSpirituality.checked) {
            data.lists.push(e.target.elements.faithAndSpirituality.value)
        }

        if (e.target.elements.positivelyPets.checked) {
            data.lists.push(e.target.elements.positivelyPets.value)
        }

        if (e.target.elements.inspirationForBusyMoms.checked) {
            data.lists.push(e.target.elements.inspirationForBusyMoms.value)
        }

        if (e.target.elements.inspirationForYourCancerJourney.checked) {
            data.lists.push(e.target.elements.inspirationForYourCancerJourney.value)
        }

        if (data.lists.length === 0) {
            listSelected = false
            setListSelected(false)
            document.getElementById('list-selection-error').scrollIntoView()
        } else {
            listSelected = true
            setListSelected(true)
        }

        if (e.target.elements['g-recaptcha-response'].value === '') {
            captchaChecked = false
            setCaptchaChecked(false)
        } else {
            captchaChecked = true
            setCaptchaChecked(true)
            data.recaptcha = e.target.elements['g-recaptcha-response'].value
        }

        if (!captchaChecked || !listSelected) {
            return false
        } else {
            axios.post('https://u2ko7qjlya.execute-api.us-east-1.amazonaws.com/production/subscribe', data)
                .then(result => {
                    window.location.href = '/free-stories-email-newsletters/thank-you'
                })
        }
    }

    const getCountryOptions = () => {
        return [
            {value: '', title: 'Please select a country', selected: true, disabled: true},
            {value: 'United States', title: 'United States'},
            {value: 'Afghanistan', title: 'Afghanistan'},
            {value: 'Aland Islands', title: 'Aland Islands'},
            {value: 'Albania', title: 'Albania'},
            {value: 'Algeria', title: 'Algeria'},
            {value: 'American Samoa', title: 'American Samoa'},
            {value: 'Andorra', title: 'Andorra'},
            {value: 'Angola', title: 'Angola'},
            {value: 'Anguilla', title: 'Anguilla'},
            {value: 'Antarctica', title: 'Antarctica'},
            {value: 'Antigua and Barbuda', title: 'Antigua and Barbuda'},
            {value: 'Argentina', title: 'Argentina'},
            {value: 'Armenia', title: 'Armenia'},
            {value: 'Aruba', title: 'Aruba'},
            {value: 'Australia', title: 'Australia'},
            {value: 'Austria', title: 'Austria'},
            {value: 'Azerbaijan', title: 'Azerbaijan'},
            {value: 'Bahamas', title: 'Bahamas'},
            {value: 'Bahrain', title: 'Bahrain'},
            {value: 'Bangladesh', title: 'Bangladesh'},
            {value: 'Barbados', title: 'Barbados'},
            {value: 'Belarus', title: 'Belarus'},
            {value: 'Belgium', title: 'Belgium'},
            {value: 'Belize', title: 'Belize'},
            {value: 'Benin', title: 'Benin'},
            {value: 'Bermuda', title: 'Bermuda'},
            {value: 'Bhutan', title: 'Bhutan'},
            {value: 'Bolivia', title: 'Bolivia'},
            {value: 'Bosnia and Herzegovina', title: 'Bosnia and Herzegovina'},
            {value: 'Botswana', title: 'Botswana'},
            {value: 'Bouvet Island', title: 'Bouvet Island'},
            {value: 'Brazil', title: 'Brazil'},
            {value: 'British Indian Ocean Territory', title: 'British Indian Ocean Territory'},
            {value: 'British Virgin Islands', title: 'British Virgin Islands'},
            {value: 'Brunei', title: 'Brunei'},
            {value: 'Bulgaria', title: 'Bulgaria'},
            {value: 'Burkina Faso', title: 'Burkina Faso'},
            {value: 'Burundi', title: 'Burundi'},
            {value: 'Cambodia', title: 'Cambodia'},
            {value: 'Cameroon', title: 'Cameroon'},
            {value: 'Canada', title: 'Canada'},
            {value: 'Cape Verde', title: 'Cape Verde'},
            {value: 'Cayman Islands', title: 'Cayman Islands'},
            {value: 'Central African Republic', title: 'Central African Republic'},
            {value: 'Chad', title: 'Chad'},
            {value: 'Chile', title: 'Chile'},
            {value: 'China', title: 'China'},
            {value: 'Christmas Island', title: 'Christmas Island'},
            {value: 'Cocos (Keeling) Islands', title: 'Cocos (Keeling) Islands'},
            {value: 'Colombia', title: 'Colombia'},
            {value: 'Comoros', title: 'Comoros'},
            {value: 'Congo', title: 'Congo'},
            {value: 'Cook Islands', title: 'Cook Islands'},
            {value: 'Costa Rica', title: 'Costa Rica'},
            {value: 'Croatia', title: 'Croatia'},
            {value: 'Cuba', title: 'Cuba'},
            {value: 'Cyprus', title: 'Cyprus'},
            {value: 'Czech Republic', title: 'Czech Republic'},
            {value: 'Democratic Republic of Congo', title: 'Democratic Republic of Congo'},
            {value: 'Denmark', title: 'Denmark'},
            {value: 'Disputed Territory', title: 'Disputed Territory'},
            {value: 'Djibouti', title: 'Djibouti'},
            {value: 'Dominica', title: 'Dominica'},
            {value: 'Dominican Republic', title: 'Dominican Republic'},
            {value: 'East Timor', title: 'East Timor'},
            {value: 'Ecuador', title: 'Ecuador'},
            {value: 'Egypt', title: 'Egypt'},
            {value: 'El Salvador', title: 'El Salvador'},
            {value: 'Equatorial Guinea', title: 'Equatorial Guinea'},
            {value: 'Eritrea', title: 'Eritrea'},
            {value: 'Estonia', title: 'Estonia'},
            {value: 'Ethiopia', title: 'Ethiopia'},
            {value: 'Falkland Islands', title: 'Falkland Islands'},
            {value: 'Faroe Islands', title: 'Faroe Islands'},
            {value: 'Federated States of Micronesia', title: 'Federated States of Micronesia'},
            {value: 'Fiji', title: 'Fiji'},
            {value: 'Finland', title: 'Finland'},
            {value: 'France', title: 'France'},
            {value: 'French Guyana', title: 'French Guyana'},
            {value: 'French Polynesia', title: 'French Polynesia'},
            {value: 'French Southern Territories', title: 'French Southern Territories'},
            {value: 'Gabon', title: 'Gabon'},
            {value: 'Gambia', title: 'Gambia'},
            {value: 'Georgia', title: 'Georgia'},
            {value: 'Germany', title: 'Germany'},
            {value: 'Ghana', title: 'Ghana'},
            {value: 'Gibraltar', title: 'Gibraltar'},
            {value: 'Greece', title: 'Greece'},
            {value: 'Greenland', title: 'Greenland'},
            {value: 'Grenada', title: 'Grenada'},
            {value: 'Guadeloupe', title: 'Guadeloupe'},
            {value: 'Guam', title: 'Guam'},
            {value: 'Guatemala', title: 'Guatemala'},
            {value: 'Guinea', title: 'Guinea'},
            {value: 'Guinea-Bissau', title: 'Guinea-Bissau'},
            {value: 'Guyana', title: 'Guyana'},
            {value: 'Haiti', title: 'Haiti'},
            {value: 'Heard Island and Mcdonald Islands', title: 'Heard Island and Mcdonald Islands'},
            {value: 'Honduras', title: 'Honduras'},
            {value: 'Hong Kong', title: 'Hong Kong'},
            {value: 'Hungary', title: 'Hungary'},
            {value: 'Iceland', title: 'Iceland'},
            {value: 'India', title: 'India'},
            {value: 'Indonesia', title: 'Indonesia'},
            {value: 'Iraq', title: 'Iraq'},
            {value: 'Iraq-Saudi Arabia Neutral Zone', title: 'Iraq-Saudi Arabia Neutral Zone'},
            {value: 'Ireland', title: 'Ireland'},
            {value: 'Israel', title: 'Israel'},
            {value: 'Italy', title: 'Italy'},
            {value: 'Ivory Coast', title: 'Ivory Coast'},
            {value: 'Jamaica', title: 'Jamaica'},
            {value: 'Japan', title: 'Japan'},
            {value: 'Jordan', title: 'Jordan'},
            {value: 'Kazakhstan', title: 'Kazakhstan'},
            {value: 'Kenya', title: 'Kenya'},
            {value: 'Kiribati', title: 'Kiribati'},
            {value: 'Kuwait', title: 'Kuwait'},
            {value: 'Kyrgyzstan', title: 'Kyrgyzstan'},
            {value: 'Laos', title: 'Laos'},
            {value: 'Latvia', title: 'Latvia'},
            {value: 'Lebanon', title: 'Lebanon'},
            {value: 'Lesotho', title: 'Lesotho'},
            {value: 'Liberia', title: 'Liberia'},
            {value: 'Libya', title: 'Libya'},
            {value: 'Liechtenstein', title: 'Liechtenstein'},
            {value: 'Lithuania', title: 'Lithuania'},
            {value: 'Luxembourg', title: 'Luxembourg'},
            {value: 'Macau', title: 'Macau'},
            {value: 'Macedonia', title: 'Macedonia'},
            {value: 'Madagascar', title: 'Madagascar'},
            {value: 'Malawi', title: 'Malawi'},
            {value: 'Malaysia', title: 'Malaysia'},
            {value: 'Maldives', title: 'Maldives'},
            {value: 'Mali', title: 'Mali'},
            {value: 'Malta', title: 'Malta'},
            {value: 'Marshall Islands', title: 'Marshall Islands'},
            {value: 'Martinique', title: 'Martinique'},
            {value: 'Mauritania', title: 'Mauritania'},
            {value: 'Mauritius', title: 'Mauritius'},
            {value: 'Mayotte', title: 'Mayotte'},
            {value: 'Mexico', title: 'Mexico'},
            {value: 'Moldova', title: 'Moldova'},
            {value: 'Monaco', title: 'Monaco'},
            {value: 'Mongolia', title: 'Mongolia'},
            {value: 'Montserrat', title: 'Montserrat'},
            {value: 'Morocco', title: 'Morocco'},
            {value: 'Mozambique', title: 'Mozambique'},
            {value: 'Myanmar', title: 'Myanmar'},
            {value: 'Namibia', title: 'Namibia'},
            {value: 'Nauru', title: 'Nauru'},
            {value: 'Nepal', title: 'Nepal'},
            {value: 'Netherlands', title: 'Netherlands'},
            {value: 'Netherlands Antilles', title: 'Netherlands Antilles'},
            {value: 'New Caledonia', title: 'New Caledonia'},
            {value: 'New Zealand', title: 'New Zealand'},
            {value: 'Nicaragua', title: 'Nicaragua'},
            {value: 'Niger', title: 'Niger'},
            {value: 'Nigeria', title: 'Nigeria'},
            {value: 'Niue', title: 'Niue'},
            {value: 'Norfolk Island', title: 'Norfolk Island'},
            {value: 'North Korea', title: 'North Korea'},
            {value: 'Northern Mariana Islands', title: 'Northern Mariana Islands'},
            {value: 'Norway', title: 'Norway'},
            {value: 'Oman', title: 'Oman'},
            {value: 'Pakistan', title: 'Pakistan'},
            {value: 'Palau', title: 'Palau'},
            {value: 'Palestinian Occupied Territories', title: 'Palestinian Occupied Territories'},
            {value: 'Panama', title: 'Panama'},
            {value: 'Papua New Guinea', title: 'Papua New Guinea'},
            {value: 'Paraguay', title: 'Paraguay'},
            {value: 'Peru', title: 'Peru'},
            {value: 'Philippines', title: 'Philippines'},
            {value: 'Pitcairn', title: 'Pitcairn'},
            {value: 'Poland', title: 'Poland'},
            {value: 'Portugal', title: 'Portugal'},
            {value: 'Puerto Rico', title: 'Puerto Rico'},
            {value: 'Qatar', title: 'Qatar'},
            {value: 'Reunion', title: 'Reunion'},
            {value: 'Romania', title: 'Romania'},
            {value: 'Russia', title: 'Russia'},
            {value: 'Rwanda', title: 'Rwanda'},
            {value: 'Saint Helena and Dependencies', title: 'Saint Helena and Dependencies'},
            {value: 'Saint Kitts and Nevis', title: 'Saint Kitts and Nevis'},
            {value: 'Saint Lucia', title: 'Saint Lucia'},
            {value: 'Saint Pierre and Miquelon', title: 'Saint Pierre and Miquelon'},
            {value: 'Saint Vincent and the Grenadines', title: 'Saint Vincent and the Grenadines'},
            {value: 'Samoa', title: 'Samoa'},
            {value: 'San Marino', title: 'San Marino'},
            {value: 'Sao Tome and Principe', title: 'Sao Tome and Principe'},
            {value: 'Saudi Arabia', title: 'Saudi Arabia'},
            {value: 'Senegal', title: 'Senegal'},
            {value: 'Serbia and Montenegro', title: 'Serbia and Montenegro'},
            {value: 'Seychelles', title: 'Seychelles'},
            {value: 'Sierra Leone', title: 'Sierra Leone'},
            {value: 'Singapore', title: 'Singapore'},
            {value: 'Slovakia', title: 'Slovakia'},
            {value: 'Slovenia', title: 'Slovenia'},
            {value: 'Solomon Islands', title: 'Solomon Islands'},
            {value: 'Somalia', title: 'Somalia'},
            {value: 'South Africa', title: 'South Africa'},
            {value: 'South Georgia and South Sandwich Islands', title: 'South Georgia and South Sandwich Islands'},
            {value: 'South Korea', title: 'South Korea'},
            {value: 'Spain', title: 'Spain'},
            {value: 'Spratly Islands', title: 'Spratly Islands'},
            {value: 'Sri Lanka', title: 'Sri Lanka'},
            {value: 'Sudan', title: 'Sudan'},
            {value: 'Suriname', title: 'Suriname'},
            {value: 'Svalbard and Jan Mayen', title: 'Svalbard and Jan Mayen'},
            {value: 'Swaziland', title: 'Swaziland'},
            {value: 'Sweden', title: 'Sweden'},
            {value: 'Switzerland', title: 'Switzerland'},
            {value: 'Syria', title: 'Syria'},
            {value: 'Taiwan', title: 'Taiwan'},
            {value: 'Tajikistan', title: 'Tajikistan'},
            {value: 'Tanzania', title: 'Tanzania'},
            {value: 'Thailand', title: 'Thailand'},
            {value: 'Togo', title: 'Togo'},
            {value: 'Tokelau', title: 'Tokelau'},
            {value: 'Tonga', title: 'Tonga'},
            {value: 'Trinidad and Tobago', title: 'Trinidad and Tobago'},
            {value: 'Tunisia', title: 'Tunisia'},
            {value: 'Turkey', title: 'Turkey'},
            {value: 'Turkmenistan', title: 'Turkmenistan'},
            {value: 'Turks And Caicos Islands', title: 'Turks And Caicos Islands'},
            {value: 'Tuvalu', title: 'Tuvalu'},
            {value: 'Uganda', title: 'Uganda'},
            {value: 'Ukraine', title: 'Ukraine'},
            {value: 'UAE', title: 'UAE'},
            {value: 'United Kingdom', title: 'United Kingdom'},
            {value: 'United Nations Neutral Zone', title: 'United Nations Neutral Zone'},
            {value: 'United States Minor Outlying Islands', title: 'United States Minor Outlying Islands'},
            {value: 'Uruguay', title: 'Uruguay'},
            {value: 'US Virgin Islands', title: 'US Virgin Islands'},
            {value: 'Uzbekistan', title: 'Uzbekistan'},
            {value: 'Vanuatu', title: 'Vanuatu'},
            {value: 'Vatican City', title: 'Vatican City'},
            {value: 'Venezuela', title: 'Venezuela'},
            {value: 'VietNam', title: 'VietNam'},
            {value: 'Wallis and Futuna', title: 'Wallis and Futuna'},
            {value: 'Western Sahara', title: 'Western Sahara'},
            {value: 'Yemen', title: 'Yemen'},
            {value: 'Yugoslavia', title: 'Yugoslavia'},
            {value: 'Zambia', title: 'Zambia'},
            {value: 'Zimbabwe', title: 'Zimbabwe'}
        ]
    }

    const unCheck = (e) => {
        let target

        switch(e.target.value){
            case 'weekly-bulletin':
                target = 'daily-bulletin'
                break;
            case 'daily-bulletin':
                target= 'weekly-bulletin'
                break;
        }

        if (target && e.target.checked) {
            document.querySelector('input[value="' + target + '"]').checked = false
        }
    }

    const setCustomValidity = (e) => {
        switch(e.target.name) {
            case 'agree_to_terms':
                e.target.setCustomValidity('Please read and accept these terms to proceed')
                break;
        }
    }

    const unsetCustomValidity = (e) => {
        e.target.setCustomValidity('')
    }

    return (
        <>
            <InnerLayout seomatic={data.craft.entry.seomatic} entryId={data.craft.entry.id}>
                <div className="midd free-stories-email-newsletters-page">
                    <PageTitle title={data.craft.entry.pageTitle}/>

                    <div className="content-page">
                        <div className="container-small">
                            <div className="static-page-banner">
                                <HeroImage image={data.craft.entry.newsletterHeroImage}/>
                            </div>
                            <div className="content-colum-row">
                                {parse(data.craft.entry.newsletterBody)}

                                <form className="form-step-block-story" onSubmit={subscribe}>
                                    <h4>Enter Your Information</h4>

                                    <div className="story-frm-step">
                                        <div className="frm-row">
                                            <label>Email Address<span>*</span></label>
                                            <input type="email" name="email" required/>
                                        </div>
                                        <div className="frm-row">
                                            <label>Country<span>*</span></label>
                                            <Select options={getCountryOptions()} name="country" required={true}/>
                                        </div>
                                    </div>

                                    <div className="choose-to-story">
                                        <div className="step-form">
                                            <h4>Choose Your Newsletters</h4>
                                            <span id="list-selection-error" className={isFormSubmitted && !isListSelected ? 'error visible' : 'error'}>You must select at least 1 newsletter</span>

                                            <div className="chkFlex">
                                                <input type="checkbox" name="dailyBulletin" value="daily-bulletin" onClick={unCheck}/>
                                                <p>Your <strong>Daily Inspiration:</strong> A free Chicken Soup for the Soul story in your email every day. Sample stories from our bestselling books!</p>
                                            </div>
                                            <em>or</em>
                                            <div className="chkFlex">
                                                <input type="checkbox" name="weeklyBulletin" value="weekly-bulletin" onClick={unCheck}/>
                                                <p>Your <strong>Weekly Inspiration:</strong> A free Chicken Soup for the Soul story just once a week, on Wednesdays. Sample our bestselling books!</p>
                                            </div>
                                        </div>

                                        <div className="step-form">
                                            <div className="chkFlex">
                                                <input type="checkbox" name="faithAndSpirituality" value="faith-and-spirituality" />
                                                <p><strong>Faith & Spirituality:</strong> A free Chicken Soup for the Soul story in your email every Monday. Miracles, angels, messages from heaven, and more! </p>
                                            </div>
                                            <div className="chkFlex">
                                                <input type="checkbox" name="positivelyPets" value="positively-pets"/>
                                                <p><strong>Positively Pets:</strong> A free Chicken Soup for the Soul story in your email every Friday. Entertainment and inspiration from our dogs, cats, and other pets.</p>
                                            </div>
                                            <div className="chkFlex">
                                                <input type="checkbox" name="inspirationForBusyMoms" value="inspiration-for-busy-moms"/>
                                                <p><strong>Inspiration for Busy Moms:</strong> A free Chicken Soup for the Soul story in your email every Tuesday. Entertainment and practical advice&mdash;moms sharing with moms.</p></div>
                                            <div className="chkFlex">
                                                <input type="checkbox" name="inspirationForYourCancerJourney" value="inspiration-for-your-cancer-journey"/>
                                                <p><strong>Inspiration for Your Cancer Journey:</strong> A free Chicken Soup for the Soul story in your email every Thursday. Support and understanding for you and your loved ones.</p>
                                            </div>
                                        </div>

                                        <div className="step-form story-small-text-mob">
                                            <p>You can unsubscribe at any time by clicking on the "Unsubscribe" link at
                                                the
                                                bottom of your newsletter emails, which will be sent via Upland PostUp,
                                                the
                                                email service provider for our newsletters.</p>
                                            <div className="chkFlex">
                                                <input type="checkbox" name="agree_to_terms" required onInvalid={setCustomValidity} onInput={unsetCustomValidity} />
                                                <p>I agree with the Chicken Soup for the Soul <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Statement</a> and <a href="/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>, and acknowledge that the information I provide will be transferred to Upland PostUp for processing in accordance with <a href="https://uplandsoftware.com/privacy/" target="_blank" rel="noreferrer">their Privacy Policy</a>.<span className="required">*</span></p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="captcha">
                                        <h5>Captcha</h5>
                                        <p>This question is for testing whether you are a human visitor and to prevent
                                            automated spam submissions.</p>
                                        <span className={isFormSubmitted && !isCaptchaChecked ? 'error visible' : 'error'}>The ReCaptcha checkbox below is required</span>
                                        {isBrowser && <Recaptcha sitekey="6LeabywdAAAAAEmwBonkAQYQV06DY5Xk_fPoHtOo"/>}
                                    </div>
                                    <br/>
                                    <div className="submit-btn">
                                        <input className="btn btn-primary" type="submit" value="Submit"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </InnerLayout>
        </>
    )
}

export default Page
